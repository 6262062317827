import React from "react"

import "../../styles/index.scss"
import "../../styles/secondary_pages.scss"

import Layout from "../../components/layout"
import SEO from "../../components/seo"


import { Link, graphql } from "gatsby"


import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLongArrowAltRight } from '@fortawesome/free-solid-svg-icons'
import { faCaretRight } from '@fortawesome/free-solid-svg-icons'


import BannerMobile from "../../images/editorial/payor-cc-banner-mobile.jpg"
import Banner from "../../images/editorial/payor-cc-banner.jpg"

import IconRevCycle from "../../images/svg/icon-rev-cycle.svg"
import IconContracting from "../../images/svg/icon-contracting-credentialing.svg"
import IconConsulting from "../../images/svg/icon-mgmt-services-consulting.svg"
import IconPracticeMgmt from "../../images/svg/icon-practice-mgmt.svg"

import ImgPayorCC from "../../images/svg/payor-contracting.svg"
import ImgOnePartner from "../../images/svg/one-partner-one-solution.svg"
import ImgExpertise from "../../images/svg/expertise.svg"
import ImgBuildingBlocks from "../../images/svg/building-blocks.svg"
import ImgReimbursement from "../../images/svg/biggest-reimbursement.svg"

const RevCycle = () => (

  <React.Fragment>
    <SEO title="Payor Contacting and Credentialing" description="Optimize your payor contract & credentialing process – and maximize your revenue.For more than 35 years, MSM has been partnering with practices to maximize profitsand drive down costs." />
    <Layout>

      <section id="sct-uvp" className="container ctr-headline secondary-headline">
        <div className="frame-image">
          <article>

            <h2>Get paid what <br /><span>you’re due.</span></h2>
            <p>Optimize your payor contracts — and maximize your revenue.</p>

            <button className="btn-primary">
              <a href="#learnmore">Learn More</a>
            </button>
          </article>

          <picture>
            <source srcSet={BannerMobile} media="(max-width:600px)" />
            <source srcSet={Banner} />
            <img src={Banner} alt="" className="lazyload" />
          </picture>


        </div>
      </section>

      <section className="container ctr-potential ctr-newframework" style={{ backgroundColor: "#389ebf" }} id="sct-newframework">
        <div className="animated ">
          <h2>Delivering superior patient care is your specialty — maximizing contract performance is ours. </h2>
          <p>Right now, you could be leaving money on the table simply because — similar to many practices — your contracting and credentialing process isn’t being handled correctly, resulting in denials and lost revenue. </p>

          <p>Let’s fix that — and ensure you’re paid every dollar possible. That means choosing the right contracts, negotiating the right rates, and bringing data into the conversation to maximize the performance of your revenue cycle process. We’ll guide you every step of the way.
     </p>

        </div>


        <img src={ImgPayorCC} />

      </section>
      <a name="learnmore" />
      <section className="container ctrinfoGraphicContainer" >
        <h3>Here’s how we stand out from the rest: </h3>
        <div className="content">
          <article className="ctrInfoGraphic">
            <div className="article-content">
              <h4>
                One partner, one solution
      </h4>
              <p>Practices don’t have time to wrangle multiple vendors. Fortunately, our payor contracting and credentialing services integrate with MSM’s Revenue Cycle Management solution to streamline the process under one roof.</p>
            </div>
            <div className="svg svg-vert">
              <img src={ImgOnePartner} />
            </div>
          </article>

          <article className="ctrInfoGraphic">
            <div className="article-content">
              <h4>
                Unparalleled expertise
      </h4>
              <p>We know the market and have our finger on the pulse of the rapidly-changing healthcare landscape. MSM negotiates the optimal contracts and rates for your business.</p>
            </div>
            <div className="svg">
              <img src={ImgExpertise} />
            </div>

          </article>
          <article className="ctrInfoGraphic">
            <div className="article-content">
              <h4>
                Business intelligence
      </h4>
              <p>You can’t learn from data that isn’t there. MSM delivers real-time data and analytics so you have a transparent view into contract performance. </p>
            </div>
            <div className="svg">
              <img src={ImgBuildingBlocks} />
            </div>

          </article>
          <article className="ctrInfoGraphic">
            <div className="article-content">
              <h4>
                The biggest reimbursement
      </h4>
              <p>Improper credentialing is a big source of profit loss for many practices. By strategically pairing people, technology, and the right credentialing, we put more money back into your revenue cycle than any other solution. </p>
            </div>
            <div className="svg svg-vert">
              <img src={ImgReimbursement} />
            </div>

          </article>

        </div>

      </section>

      <section className="container ctr-cta" style={{ backgroundColor: "#16cac3", color: "#fff" }}>
        <h3>Let us maximize your revenue.</h3>
        <p> Explore MSM’s payor contracting and credentialing services today. </p>
        <button className="btn-primary btn-inverse" style={{ backgroundColor: "#fff", color: "#16cac3" }}>
          <Link to="/contact">Learn More</Link>
        </button>
      </section>

    </Layout>




  </React.Fragment >

)

export default RevCycle;